/* eslint-disable no-underscore-dangle */
import { HStack, Heading, IconButton, Stack, Box, Switch, Tooltip } from '@chakra-ui/react';
import { faFileCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import EAInput from '../../components/Form/EAInput';
import { IApplicationWebHook } from '../../dtos/app-connect';
import AppHeadersModal from './AppHeadersModal';



type AppCardWebHookProps = {
  idx: number;
  webhook: IApplicationWebHook
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onChange?: (idx: number, webhook: IApplicationWebHook | undefined) => void;
};


const AppCardWebHook = ({ idx, webhook, action, onChange = () => {} }: AppCardWebHookProps) => {
  const [data, setData] = useState<IApplicationWebHook | undefined>(webhook);
  const [openModalHeader, setOpenModalHeader] = useState(false)
  
  const updateValue = (property: string, value: any)=>{
    let obj = data;
    if (obj)
      obj[property] = value      
    setData(obj)

    onChange(idx, obj)
  }
  
  return (
    <Stack>
      <Box p={5} shadow='md' borderWidth='1px' borderRadius={10}>
        <HStack alignContent="baseline" align="end"  display="flex" justifyContent="space-between" >
          <Heading fontSize='xl'>{data?.description}</Heading>     
          <Switch
            id="active"
            isDisabled={action === 'VIEW'}
            colorScheme="green" size="lg"
            defaultChecked={data?.active === undefined ? true : data.active}
            onChange={(value) => updateValue('active', value.target.checked)}
          />
        </HStack>
        
        <HStack alignContent="baseline" align="end">
          <EAInput
            name="webhook"
            isReadOnly={action === 'VIEW'}
            defaultValue={data?.webhook || ''}
            autoComplete="none"
            placeholder="URL do serviço que o webhook deve chamar"
            onChange={(value) => updateValue('webhook', value.target.value)}
            
          />
          <Tooltip label={action === 'VIEW'? 'Visualizar cabeçalho do webhook' : 'Adicionar cabeçalho do webhook'}>
            <IconButton
              ml={0}
              colorScheme='facebook'
              aria-label='Adicionar Headers ao webhook'
              icon={<FontAwesomeIcon icon={faFileCode} />}
              onClick={() => setOpenModalHeader(true)}
            />
          </Tooltip>
        </HStack>
      </Box>      
      { 
            openModalHeader ? 
            <AppHeadersModal 
              formTitle="Cabeçalho do WebHook"
              value={data?.headers}
              action={action}
              onCloseForm={(cancel, value)=>{
                if(!cancel)
                  updateValue('headers', value)
                setOpenModalHeader(false)
              }} /> : 
            null
          }
    </Stack>
  );
};

export default AppCardWebHook;
