/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import api from '../../services/api';
import DataTableComponent, { ColumnHeaders } from '../../components/DataTable';
import { IAppConnectWebHook, IAppConnectWebHookQueue } from '../../dtos/app-connect-webhook';
import { Stack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileCode } from '@fortawesome/free-regular-svg-icons';
import EAPreviewJSONModal from '../../components/EAPreviewJSONModal';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

interface IAppConnectWebHookQueueDoc extends IAppConnectWebHookQueue {
  resource?: string;
}

type IAppConnectWebHookTabQueueProps = {
  webhookId?: string;
};

const AppConnectWebHookTabQueue = ({ webhookId } : IAppConnectWebHookTabQueueProps) => {
  const ref = useRef(null)
  const [modalJsonTitle, setModalJsonTitle] = useState<string>('');
  const [modalJson, setModalJson] = useState<any>(null);
  const [dataList, setDataList] = useState<IAppConnectWebHookQueueDoc[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(7);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  
  const getData = async ({ filter, sort, page, offset, limit = rowsPerPage }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      const query: string[] = [];
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }
            
      setDataList([]);
      setAllRows(0);
      if (webhookId){        
        const response: AxiosResponse = await api.get(`/app-connect/webhook/${webhookId}/queue-list${query.length > 0 ? `?${query.join('&')}` : ''}`);
        if (response && response.data) {
          const docs: IAppConnectWebHookQueueDoc[] = response.data.rows || []
          setDataList(docs.map(doc => ({
            ...doc,
            // status: doc?.status === 'FAILURE' ? 'FALHA' : doc?.status === 'QUEUED' ? 'NA FILA' : 'SUCESSO',
            resource: (doc?.webHook as IAppConnectWebHook)?.description,
          })));
          setAllRows(response.data.allRows);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  
  const actionJsonEnvio = async (item: IAppConnectWebHookQueueDoc, newPage: boolean) => {
    try {
      const { data }: AxiosResponse = await api.get(`/app-connect/webhook/${webhookId}/queue/${item._id}/payload`);
      if (data) {
          if (newPage){
            const newWindow = window.open("", "_blank")
            newWindow?.document.write('<html><head><title>JSON de Envio</title></head><body><pre>' + JSON.stringify(data, null, 2) + '</pre></body></html>');
            newWindow?.focus()
          } else {
            setModalJsonTitle('JSON de Envio')
            setModalJson(data)
          }
      }
    } catch (error) {
      
    } finally {

    }
  }

  const actionJsonResponse = async (item: IAppConnectWebHookQueueDoc, newPage: boolean) => {
    try {
      const { data }: AxiosResponse = await api.get(`/app-connect/webhook/${webhookId}/queue/${item._id}/response`);
      if (data) {
          if (newPage){
            const newWindow = window.open("", "_blank")
            newWindow?.document.write('<html><head><title>JSON de Retorno</title></head><body><pre>' + JSON.stringify(data, null, 2) + '</pre></body></html>');
            newWindow?.focus()
          } else {
            setModalJsonTitle('JSON de Retorno')
            setModalJson(data)
          }
      }
    } catch (error) {
      
    } finally {

    }
  }

  useEffect(() => {      
    setHeaders([
      { text: 'Dt. Inclusão', value: 'queuedAt', type: 'datetime', align: 'center', cellClass: {w: '25%'}, sortable: false },
      { text: 'Status', value: 'status', align: 'center', cellClass: {w: '10%'}, sortable: false },
      { text: 'Recurso', value: 'resource', alignHeader: 'center', sortable: false },
      { text: 'Dt. Processamento', value: 'processedAt', type: 'datetime', align: 'center', cellClass: {w: '25%'}, sortable: false },
      { text: 'HTTP Status', value: 'httpStatus', align: 'center', cellClass: {w: '10%'}, sortable: false }
    ]);
    getData({})
  }, []);

  return (
    <>
      { modalJsonTitle && modalJson ? <EAPreviewJSONModal title={modalJsonTitle} json={modalJson} onCloseForm={() => {setModalJsonTitle(''); setModalJson(null)}} /> : null }
      <Stack ref={ref} d="flex" w="100%">
        <DataTableComponent
          isLoading={isLoading}
          headers={headers}
          itens={dataList}
          filter={filterValue}
          paginationOptions={{
            allItems: allRows,
            itemsPerPage: rowsPerPage,
            page: currentPage,
          }}
          tableSync={(filter, sort, page, offset, limit) => {
            getData({ filter, sort, page, offset, limit });
          }}
          itemCustomAction1={
            {
              name: 'JsonEnvio',
              tooltip: 'Visualizar Json de Envio',
              itemAction: (item)=> {actionJsonEnvio(item, false)},
              iconButtonProps: {
                key:"itemCustomAction1",
                'aria-label': "Edit",
                size:"xs",
                variant:"outline",
                color:"blue.500",
                icon:<FontAwesomeIcon icon={faFileCode} />
              }
            }
          }
          itemCustomAction2={
            {
              name: 'OpenJsonResponse',
              tooltip: 'Abrir Json Envio em nova aba',
              itemAction: (item)=> {actionJsonEnvio(item, true)},
              iconButtonProps: {
                key:"itemCustomAction2",
                'aria-label': "Edit",
                size:"xs",
                variant:"outline",
                color:"blue.500",
                icon:<FontAwesomeIcon icon={faExternalLinkAlt} />
              }
            }
          }
          itemCustomAction3={
            {
              name: 'JsonResponse',
              tooltip: 'Visualizar Json de Retorno',
              itemAction: (item)=> {actionJsonResponse(item, false)},
              iconButtonProps: {
                key:"itemCustomAction3",
                'aria-label': "Edit",
                size:"xs",
                variant:"outline",
                color:"blue.500",
                icon:<FontAwesomeIcon icon={faFileCode} />
              }
            }
          }
          itemCustomAction4={
            {
              name: 'OpenJsonResponse',
              tooltip: 'Abrir Json de Retorno em nova aba',
              itemAction: (item)=> {actionJsonResponse(item, true)},
              iconButtonProps: {
                key:"itemCustomAction4",
                'aria-label': "Edit",
                size:"xs",
                variant:"outline",
                color:"blue.500",
                icon:<FontAwesomeIcon icon={faExternalLinkAlt} />
              }
            }
          }
          // columnSortable={sortable}
        />
      </Stack>
    </>
  );
};

export default AppConnectWebHookTabQueue;
