import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalContent, ModalHeader, ModalOverlay, ModalBody, ModalFooter } from '@chakra-ui/modal';
import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Box } from '@chakra-ui/layout';

interface EAPreviewJSONModalProps {
  title: string
  json?: any
  onCloseForm?: () => void;
}

const EAPreviewJSONModal: React.FC<EAPreviewJSONModalProps> = ({title, json, onCloseForm}: EAPreviewJSONModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const closeForm = () => {
      if (onCloseForm) {
        onCloseForm();
      }
      onClose();
    };
    useEffect(() => {
      onOpen();
    }, []);

    return (
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={closeForm}
        size="6xl" >
        <ModalOverlay />
        <ModalContent height="80vh">
          <ModalHeader textAlign="center" >
            {title}
          </ModalHeader>        
          <ModalBody pb={8}overflowX="auto">
            <Box mt={1}>
              <pre>{JSON.stringify(json, null, 2) }</pre>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => closeForm()}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
};

export default EAPreviewJSONModal;
