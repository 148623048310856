import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';

import Select, { GroupTypeBase, OptionTypeBase, StylesConfig } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';

import { useField } from '@unform/core';
import { darken } from 'polished';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { NamedProps, SelectComponentsProps } from 'react-select/src/Select';

export interface ISelectOptions {
  value: string;
  label: string;
}

interface EASelectProps extends SelectComponentsProps {
  name: string;
  label?: string;
  isInvisible?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  isMulti?: boolean;
  formRef?: MutableRefObject<any>;
  asyncSearch?: (text?: string) => Promise<ReadonlyArray<OptionTypeBase | GroupTypeBase<OptionTypeBase>>> | void
  
  /** Text to display when there are no options */
  noOptionsMessage?: (obj: { inputValue: string }) => string | null;
  /** The value of the select; reflected by the selected option */
  value?: readonly OptionTypeBase[] | OptionTypeBase | null;
  /** Array of options that populate the select menu */
  options?: ReadonlyArray<OptionTypeBase | GroupTypeBase<OptionTypeBase>>;
  /** Style modifier methods */
  styles?: StylesConfig<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>;
  /** Is the select in a state of loading (async) */
  isLoading?: boolean;
}

const EASelect: React.FC<EASelectProps> = ({ name, label, isInvisible, asyncSearch, formRef, onChange = ()=> {}, ...props }) => {
  const inputRef = useRef(null);
  const { fieldName, error, registerField } = useField(name);
  let textValue: string;
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
    if (formRef){
      formRef.current.setFieldValue(fieldName, props.value)
    }
    
  }, [fieldName, registerField]);
  const changeValue = (value) => {
    if (formRef){
      formRef.current.setFieldValue(fieldName, value)
    }
    onChange(value)
  }
  const stringEquals = (var1:string, var2:string) => {
    if (!var1 && !var2){
      return true;
    } else if (var1 && var2 && var1 === var2){
      return true;
    }
    return false;
  }
  const promiseSearch = (inputValue: string) => { 
    textValue = inputValue
    return new Promise<any>((resolve) => {
      setTimeout(() => {
        return resolve(asyncSearch && stringEquals(textValue, inputValue) ? asyncSearch(inputValue): null);
      }, 500);
    });
  };
  
  if (isInvisible){
    return null
  } else {
    return (
      <FormControl isInvalid={!!error} isRequired={props.isRequired} isReadOnly={props.isReadOnly} >
        <FormLabel htmlFor={"input-"+fieldName} size="xs">{label}</FormLabel>
        { asyncSearch ?
          <AsyncSelect
            id={"select-input-"+fieldName }
            ref={inputRef}
            size="md"
            color={(props.isReadOnly ? darken(0.2,'#666360') : '#41414A')}
            background={(props.isReadOnly ? darken(0.2, '#FFF') : '#FFF')}
            defaultOptions={!props.isReadOnly}
            loadOptions={promiseSearch} 
            isDisabled={props.isReadOnly}
            onChange={changeValue}
            {...props}
          />
        :
        <Select
          id={"select-input-"+fieldName }
          ref={inputRef}
          size="md"
          color={(props.isReadOnly ? darken(0.2,'#666360') : '#41414A')}
          background={(props.isReadOnly ? darken(0.2, '#FFF') : '#FFF')}
          isDisabled={props.isReadOnly}
          onChange={changeValue}
          {...props} />
        }
        
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    )
  }
};

export default EASelect;
