import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';
// import AppProvider from './hooks';
import SplashScreen from './pages/SplashScreen';
import { useAuth } from './hooks/AuthContext';
import AppConfig from './config/AppConfig';

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { signInToken } = useAuth();
  useEffect(() => {
    (async () => {
      await signInToken();
      setLoading(false);
    })();
    document.title = 'E-Cooperativa Connect';
    if (AppConfig.company) {
      document.title = `E-Cooperativa Connect | ${AppConfig.company}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <Router>
          <Routes />
        </Router>
      )}
    </>
  );
};

export default App;
