/* eslint-disable no-underscore-dangle */
import {  Button,  Table, Thead, Tbody, Tr, Th, Td, Modal, Input, IconButton, ModalBody,  ModalCloseButton,  ModalContent,  ModalFooter,  ModalHeader,  ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { faCheck, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import EAConfirmationDialog from '../../components/EAConfirmationDialog';
import { darken } from 'polished';

export interface IAppHeaders {
  [key: string]: string;
}

interface IHeaders {
  key: string;
  value: string;
}

type AppHeadersModalProps = {
  formTitle: string
  value?: IAppHeaders;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (cancel: boolean, newData: IAppHeaders | undefined) => void;
};


const AppHeadersModal = ({ formTitle, value, action, onCloseForm }: AppHeadersModalProps) => {
  const [originalData] = useState<IAppHeaders | undefined>(value);
  const [headers, setHeaders] = useState<IHeaders[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [msgConfirmation, setMsgConfirmation] = useState<string | undefined>();
  const [idxConfirmation, setIdxConfirmation] = useState<number | undefined>();

  const addHeader = ()=>{
    setHeaders([...headers, { key: '', value: ''}])
  }
  const updateHeader = (idx: number, property: string, value: string)=>{
    if (headers.length > 0 && headers[idx]){
      let hs = headers;
      hs[idx][property] = value      
      setHeaders(hs)
    }
  }
  const deleteHeader = useCallback((idx: number, header: IHeaders)=>{
    setIdxConfirmation(idx)
    setMsgConfirmation("Confirma excusão do registro?")
  }, [])

  const confirmDeleteHeader = (idx: number| undefined)=>{
    // console.log('confirmDeleteHeader idx: ', idx)
    if (idx && idx >= 0){
      headers.splice(idx, 1)
      setHeaders([...headers])
    }
    // setTimeout(()=> console.log('confirmDeleteHeader headers: ', headers), 1000)
    setIdxConfirmation(undefined)
    setMsgConfirmation(undefined)
    
  }

  const closeForm = (cancel = true, data?: any) => {
    if (onCloseForm) {
      onCloseForm(cancel, (cancel ? originalData : data));
    }
    onClose();
  };
  
  const getHeaderList = () => {
    const list : IHeaders[] = []
    if (originalData)
      Object.keys(originalData).forEach( key => {
        list.push({
          key: key,
          value: originalData[key]
        })
    })
    setHeaders(originalData ? 
      Object.keys(originalData).map( key => (
        {
          key: key,
          value: originalData[key]
        })):
      [])
  };


  const confirm = async () => {
    const result: IAppHeaders = {}
    // console.log('confirm => ', headers)
    for await ( const l of headers){
      if (l.key && l.key.length > 0)
        result[l.key] = l.value
    }
    // console.log('confirm result=> ', result)
    closeForm(false, result);
  };

  useEffect(() => {
    async function loadHeaders() {
      getHeaderList();
      onOpen();
    }

    loadHeaders();
  }, []);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeForm} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {formTitle}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={8} overflowX="auto">
          <Table>
            <Thead>
              <Tr alignItems="center" justifyContent="center">
                <Th>Chave</Th>
                <Th>Valor</Th>
                <Th>
                  <IconButton                  
                    ml={0}
                    colorScheme='green'
                    aria-label='Add'
                    disabled={action === 'VIEW'}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={addHeader}
                  />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {headers.map((header, idx) => (
                <Tr key={idx} alignItems="center" justifyContent="center">
                  <Td p="1" border="1"  >  
                      <Input
                        key={`key-${idx}`}
                        name={`key-${idx}`}
                        isReadOnly={action === 'VIEW'}
                        size="md"
                        color={(action === 'VIEW' ? darken(0.2,'#666360') : '#41414A')}
                        background={(action === 'VIEW' ? darken(0.2, '#FFF') : '#FFF')}
                        defaultValue={ header.key || ''}
                        autoComplete="none"
                        onChange={(ev) => updateHeader(idx, 'key', ev.target.value)}
                      />
                  </Td>
                  <Td p="1" border="1">
                      <Input
                        key={`value-${idx}`}
                        name={`key-${idx}`}
                        isReadOnly={action === 'VIEW'}
                        size="md"
                        color={(action === 'VIEW' ? darken(0.2,'#666360') : '#41414A')}
                        background={(action === 'VIEW' ? darken(0.2, '#FFF') : '#FFF')}
                        defaultValue={ header.value|| ''}
                        autoComplete="none"
                        onChange={(ev) => updateHeader(idx, 'value', ev.target.value)}
                      />
                  </Td>
                  <Td p="1" alignItems="center" justifyContent="center">
                    <IconButton                  
                      ml={0}
                      colorScheme='red'
                      aria-label='Delete'
                      disabled={action === 'VIEW'}
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() => deleteHeader(idx, header)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <EAConfirmationDialog
            isOpenDialog={!!msgConfirmation}
            textHeader="Atenção"
            textBoby={msgConfirmation}
            textActionOne="Sim"
            actionOneProps={{
              colorScheme: "red",
              onClick: () => {
                confirmDeleteHeader(idxConfirmation)
              }
            }}
            textActionTwo="Não"
            actionTwoProps={{
              onClick: () => {
                setMsgConfirmation(undefined)
              }
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={action === 'VIEW' || isSaving}
            isLoading={isSaving}
            loadingText="Saving..."
            colorScheme="green"
            mr={3}
            leftIcon={<FontAwesomeIcon icon={faCheck} />}
            onClick={confirm}>
            Confirmar
          </Button>
          <Button 
            isDisabled={ isSaving }
            mr={3} 
            colorScheme="blackAlpha" 
            leftIcon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => closeForm(true)}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AppHeadersModal;
