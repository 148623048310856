import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  HStack,
  Text,
  Heading,
  Box,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IAppConnectWebHook } from '../../dtos/app-connect-webhook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import AppConnectWebHookTabQueue from './AppConnectWebHookTabQueue';


type AppConnectWebHookFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IAppConnectWebHook) => void;
};
export interface PermissionList {
  description: string;
  feature: string;
}

const AppConnectWebHookModal = ({ dataId, action, onCloseForm }: AppConnectWebHookFormProps) => {
  const Toast = useToast();
  const [isReprocessing, setIsReprocessing] = useState(false)
  const [objectData, setObjectData] = useState<IAppConnectWebHook>({} as IAppConnectWebHook);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const reprocess = async (id?: string) => {
    if (id){
      try {  
        setIsReprocessing(true)    
        await api.post(`/app-connect/webhook/${id}/reprocess`);
        await getDocument(id);
        Toast({
          status: 'success',
          title: 'Sucesso',
          description: 'Reprocessamento efetuado!',
          position: 'top-right',
        });
      } catch (error) {
        await getDocument(id);
        Toast({
          status: 'error',
          title: 'Erro ao reprocessar Fila!',
          description: 'Não foi possível realizar o processamento da fila. Verique a resposta do WebHook!',
          position: 'top-right',
        });
      } finally {        
        setIsReprocessing(false)  
      }
    }
  };

  const getDocument = async (id: string) => {
    const response: AxiosResponse = await api.get(`/app-connect/webhook/${id}`);
    if (response && response.data) {
      const doc: IAppConnectWebHook = response.data
      setObjectData({
        ...doc
      });
    }
  };
  
  useEffect(() => {
    async function getDataFromAPI() {
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }

    getDataFromAPI();
  }, []);
  const reponseIsJson = (contentType) => {
    return contentType?.toLowerCase().includes('json');
  }

  function Field({ title, desc, isJson=false, ...rest }) {
    return (
      <Box p={1} shadow="md" borderWidth="1px" {...rest}>
        <Heading size="md" >{title}</Heading>
        {
          isJson ?
          <Box mt={1}>
            <pre>{JSON.stringify(desc, null, 2) }</pre>
          </Box> : 
          <Text mt={1}>{desc }</Text>
        }
      </Box>
    )
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={closeForm}
      size="6xl" >
      <ModalOverlay />
      <ModalContent height="80vh">
        <ModalHeader textAlign="center" >
          {'Dados da Fila de Envio para o Webhok'}
        </ModalHeader>        
        <ModalBody pb={8} overflowX="auto">    
          <Tabs isFitted variant="enclosed" onChange={console.log}>
            <TabList mb="1em">
              <Tab>WebHook</Tab>
              <Tab>Fila</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>                     
                <Stack spacing="4">
                  <HStack spacing={4}>
                    <Field title="Recurso"  flex="4" desc={objectData.description} />
                    <Field title="Status" flex="1" desc={objectData.active? 'Ativo': 'Inativo'} />
                  </HStack>
                  <HStack spacing={4}>
                    <Field title="webhook"  flex="1" desc={objectData.webhook} />
                  </HStack>
                  <HStack spacing={4}>
                    <Field title="Mensagens na fila"  flex="1" desc={objectData.queued} />
                    <Field title="Mensagens Processadas" flex="1" desc={objectData.success} />
                    <Field title="Operando" flex="1" desc={objectData.error ? 'Erro no processamento': 'Sim'} />
                  </HStack>
                  <Button
                    colorScheme="facebook"
                    mr={3}
                    disabled={(!objectData.error) || isReprocessing || (!objectData.active)}
                    isLoading={isReprocessing}
                    loadingText="Reprocessando..."                
                    icon={<FontAwesomeIcon icon={faSync} />}
                    onClick={() => reprocess(dataId)}>
                    Reprocessar Fila
                  </Button>
                  { objectData.error ? <Field title="Resposta do WehHook" isJson={reponseIsJson(objectData.responseContentType)} flex="1" desc={objectData.responseError} /> : null }
                  {/* 
                  <HStack spacing={4}>
                    <Field title="Origem"  flex="1" desc={objectData.integrationSource?.code} />
                    <Field title="Entidade" flex="1" desc={objectData.entity} />
                    <Field title="Operacao" flex="1" desc={objectData.type === 'AUTH'? 'Autenticação' : objectData.type === 'DATA' ? 'Envio Dados': 'Consulta Versão'} />

                  </HStack>
                  <HStack spacing={4}>
                    <Field title="Status"  flex="1" desc={statusText(objectData.status) } />
                    <Field title="Inicio Transação" flex="1" desc={ moment(objectData.startedAt).format('DD/MM/YYYY HH:mm:ss.SSS') } />
                    <Field title="Fim Transação" flex="1" desc={ objectData.finishedAt && moment(objectData.finishedAt).format('DD/MM/YYYY HH:mm:ss.SSS') } />
                    <Field title="Tempo" flex="1" desc={ objectData.startedAt && objectData.finishedAt && DateUtil.timeTxt(objectData.startedAt, objectData.finishedAt) } />
                  </HStack>
                  <Field title="Mensagem" desc={objectData.message} />
                  <HStack spacing={4}>
                    <Field title="Quantidade Registros" flex="1" desc={ objectData.payload && Array.isArray(objectData.payload) ? objectData.payload.length : 0 } />
                    <Field title="Tamanho estimado" flex="1" desc={ AppUtil.memorySizeOf(objectData?.payload) } />
                  </HStack>
                  {objectData.errorDetail ?
                    <HStack spacing={4} alignItems="baseline" w="100%" >
                      <Field title="Dados Alterados" isJson w="50%" desc={objectData.payload} />
                      <Field title="Detalhe de Erro" isJson w="50%" desc={objectData.errorDetail} />
                    </HStack>
                    : objectData.payload && <Field title="Dados Alterados" isJson  w="100%" desc={objectData.payload} />
                  }
                */}
                </Stack>
              </TabPanel>
              <TabPanel>
                <AppConnectWebHookTabQueue webhookId={dataId} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={() => closeForm(false)}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AppConnectWebHookModal;
