/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/toast';
import api from '../../services/api';
import DataTableComponent, { ColumnHeaders, getRowsPerPage } from '../../components/DataTable';
import { IAppConnect } from '../../dtos/app-connect';
import AppConnectModal from './AppConnectModal';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

const AppConnect = () => {
  const Toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [actionModal, setActionModal] = useState<'VIEW' | 'EDIT' | 'DEFAULT'>('DEFAULT');
  const [dataId, setDataId] = useState('');
  const [dataList, setDataList] = useState<IAppConnect[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [rowsPerPage] = useState(getRowsPerPage());
  const [currentPage, setCurrentPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sortable, setSsortable] = useState<string>();

  const getData = async ({ filter, sort, page, offset, limit = rowsPerPage }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      setSsortable(sort);
      const query: string[] = [];
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (sort) {
        query.push(`sort=${sort}`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }

      setDataList([]);
      setAllRows(0);
      const response: AxiosResponse = await api.get(`/app-connect/list${query.length > 0 ? `?${query.join('&')}` : ''}`);
      if (response && response.data) {
        setDataList(response.data.rows.map(val => ({...val, typeTxt: val.type === 'PRODUCER' ? 'Cooperado' : 'Cooperativa'})));
        setAllRows(response.data.allRows);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const closeForm = (reload = false) => {
    setActionModal('DEFAULT');
    setDataId('');
    setShowModal(false);
    if (reload) {
      getData({});
    }
  };

  useEffect(() => {
    setHeaders([
      { text: 'Código', value: 'code', align: 'center', sortable: true },
      { text: 'Nome', value: 'name', alignHeader: 'center', sortable: true },
      { text: 'Tipo', value: 'typeTxt', align: 'center', sortable: true },
      { text: 'Ativo', value: 'active', type: 'boolean', align: 'center', sortable: true },
    ]);
    getData({});
  }, []);

  return (
    <>
      {showModal ? <AppConnectModal dataId={dataId} action={actionModal} onCloseForm={closeForm} /> : null}
      <DataTableComponent
        isLoading={isLoading}
        headers={headers}
        itens={dataList}
        filter={filterValue}
        paginationOptions={{
          allItems: allRows,
          itemsPerPage: rowsPerPage,
          page: currentPage,
        }}
        tableSync={(filter, sort, page, offset, limit) => {
          getData({ filter, sort, page, offset, limit });
        }}
        newItem={() => {
          setShowModal(true);
          setActionModal('EDIT');
        }}
        itemActionEdit={(item: IAppConnect) => {
          // console.log(item.name);
          setDataId(item._id);
          setShowModal(true);
          setActionModal('EDIT');
        }}
        itemActionView={(item: IAppConnect) => {
          setDataId(item._id);
          setActionModal('VIEW');
          setShowModal(true);
        }}
        // itemActionDelete={async (item: IUser) => {
        //   inactiveItem(item);
        // }}
        columnSortable={sortable}
      />
    </>
  );
};

export default AppConnect;
