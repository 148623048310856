import React, { useEffect, useRef, useState } from 'react';
import { FormHandles, useField } from '@unform/core';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Slider, SliderFilledTrack, SliderMark, SliderProps, SliderThumb, SliderTrack, Tooltip } from '@chakra-ui/react';

interface EAInputSliderProps extends SliderProps {
  name: string;
  label?: string;
  isInvisible?: boolean;
  slideMarkText?: string | undefined;
  slideMarkDiv?: number | undefined;
}

interface IEASliderMarkPosition {
  position: number;
  label: string
}

const EAInputSlider: React.FC<EAInputSliderProps> = ({ name, label, isReadOnly, isInvisible, slideMarkDiv = 0, defaultValue = 1, slideMarkText , ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<FormHandles>(null);  
  const [showTooltip, setShowTooltip] = useState(false)
  const [slideMarkOptions, setSlideMarkOptions] = useState<IEASliderMarkPosition[]>([])
  const [currentValue, setCurrentValue] = useState(defaultValue)
  const { fieldName, error, registerField } = useField(name); 
  const setValueField = ((value) => {
    setCurrentValue(value)
  });
  useEffect(() => {
    if ( slideMarkDiv > 0){
      const opt: IEASliderMarkPosition[] = []
      for (let index = 1; index <= Math.ceil( (props.max||0) / slideMarkDiv); index++) {
        opt.push({position: (index*slideMarkDiv), label: `${(index*slideMarkDiv)} ${slideMarkText}` })      
      }
      if (opt.length > 0) {
        setSlideMarkOptions(opt)
      }
    }

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',   
    });
  }, [fieldName, registerField]);

  if (isInvisible){
    return null
  } else {
    return (    
      <FormControl isInvalid={!!error} isReadOnly={isReadOnly} >
        <FormLabel htmlFor={"slider-"+fieldName} size="xs">{label}</FormLabel>        
        <Slider
          id={"slider-"+fieldName } 
          key={"slider-"+fieldName } 
          maxW="100%"
          ref={inputRef}
          colorScheme='green'
          isReadOnly={isReadOnly}
          defaultValue={currentValue}
          onChange={(v) => setValueField(v)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}          
          {...props} 
        >
          {slideMarkOptions.length > 1 && 
              <SliderMark key={"slider-"+fieldName+"-SliderMark0-"+0 } value={1} mt='1' ml='-2.5' fontSize='sm' textAlign="start">
                1
              </SliderMark>
            }
          {
            slideMarkOptions.map((o, idx) => (            
            <SliderMark key={"slider-"+fieldName+"-SliderMark-"+o.position+"-"+idx } value={o.position} mt='1' ml='-2.5' fontSize='sm' textAlign={slideMarkOptions.length === idx ? "end": "center"}>
              {o.position}
            </SliderMark>
            ) 
          )
          }
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <Tooltip
            key={"slider-"+fieldName+"-Tooltip" }
            hasArrow
            bg='green.800'
            color='white'
            placement='top'
            isOpen={showTooltip}
            label={`${currentValue} ${slideMarkText}`}
          >
            <SliderThumb />
          </Tooltip>
        </Slider>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    )
  }
};

export default EAInputSlider;
