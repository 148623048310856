import React from 'react';
import { Switch } from 'react-router-dom';
import Home from '../pages/Home';
import SignIn from '../pages/SignIn';

import { SidebarProvider } from '../hooks/Sidebar';
import AuditComponent from '../pages/Audit';
import DataIntegrationEvent from '../pages/DataIntegrationEvent';
import DataIntegrationSource from '../pages/DataIntegrationSource';
import { ParametersGeneral } from '../pages/ParametersGeneral';
import UserComponent from '../pages/User';
import Route from './Route';
import AppConnectComponent from '../pages/AppConnect';
import AppConnectWebHookComponent from '../pages/AppConnectWebHook';
import HttpAuditRequestAccessComponent from '../pages/HttpAuditRequestAccess';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} key={'/'} />
    <SidebarProvider>
      <Route path="/home" isPrivate component={Home} key={'/home'} />
      <Route
        path="/register/user"
        isPrivate
        component={UserComponent}
        key={'/register/user'}
      />
      <Route
        path="/application/app-connect"
        isPrivate
        component={AppConnectComponent}
        key="/application/app-connect"
      />
      <Route
        path="/application/webhook"
        isPrivate
        component={AppConnectWebHookComponent}
        key="/application/webhook"
      />
      <Route
        path="/application/request-access"
        isPrivate
        component={HttpAuditRequestAccessComponent}
        key="/application/request-access"
      />
      <Route
        path="/tools/parameters/general"
        isPrivate
        component={ParametersGeneral}
        key={'/tools/parameters/general'}
      />
      <Route
        path="/tools/audit"
        isPrivate
        component={AuditComponent}
        key={'/tools/audit'}
      />
      <Route
        path="/tools/integration"
        isPrivate
        component={DataIntegrationSource}
        key={'/tools/integration'}
      />
      <Route
        path="/tools/integration-audit"
        isPrivate
        component={DataIntegrationEvent}
        key={'/tools/integration-audit'}
      />
      {/*
      <Route
        path="/tools/notification-send"
        isPrivate
        component={NotificationSend}
        key={'/tools/notification-send'}
      />*/
      }
    </SidebarProvider>
  </Switch>
);

export default Routes;
