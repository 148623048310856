import {
  Box, Button, Heading, HStack, Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack, Text, useDisclosure
} from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import EAPreviewJSONModal from '../../components/EAPreviewJSONModal';
import { IAppConnectRequestAccess } from '../../dtos/app-connect-reques-access';
import { IAudit } from '../../dtos/audit';
import api from '../../services/api';


type HttpAuditRequestAccessModalProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IAudit) => void;
};
export interface PermissionList {
  description: string;
  feature: string;
}

const HttpAuditRequestAccessModal = ({ dataId, action, onCloseForm }: HttpAuditRequestAccessModalProps) => {
  const [objectData, setObjectData] = useState<IAppConnectRequestAccess>({} as IAudit);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalJsonTitle, setModalJsonTitle] = useState<string>('');
  const [modalJson, setModalJson] = useState<any>(null);

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const getDocument = async (id: string) => {
    const { data }: AxiosResponse = await api.get(`/app-connect/request-access/${id}`);
    if (data) {
      setObjectData(data);
    }
  };
  

  const openModalJson = async (title: string, json: any) => {
    setModalJsonTitle(title)
    setModalJson(json)
  };
  useEffect(() => {
    async function getDataFromAPI() {
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }

    getDataFromAPI();
  }, []);

  function Field({ title, desc, isJson=false, ...rest }) {
    return (
      <Box p={1} shadow="md" borderWidth="1px" {...rest}>
        <Heading size="md" >{title}</Heading>
        {
          isJson ?
          <Box mt={1}>
            <pre>{JSON.stringify(desc, null, 2) }</pre>
          </Box> :
          <Text mt={1}>{desc }</Text>
        }
      </Box>
    )
  }

  return (
    <>
      { modalJsonTitle && modalJson ? <EAPreviewJSONModal title={modalJsonTitle} json={modalJson} onCloseForm={() => {setModalJsonTitle(''); setModalJson(null)}} /> : null }
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={closeForm}
        size="6xl" >
        <ModalOverlay />
        <ModalContent height="80vh">
          <ModalHeader textAlign="center" >
            {'Dados de Consultas realizadas pelo Applicativo'}
          </ModalHeader>
          <ModalBody pb={8} overflowX="auto">
              <Stack spacing="4">
                <HStack spacing={4}>
                  <Field title="Data/Hora" flex="1" desc={ moment(objectData.time).format('DD/MM/YYYY HH:mm:ss')} />
                  <Field title="Método HTTP" flex="1" desc={objectData.method} />
                  <Field title="HTTP Status"  flex="1" desc={objectData.responseStatusCode} />
                </HStack>
                <HStack spacing={4}>
                  <Field title="Agent" flex="1" desc={objectData.userAgent} />
                  <Field title="IP de Origem" flex="1" desc={objectData.fromIP} />
                </HStack>
                <Field title="URL" desc={objectData.uri} />
                <HStack spacing={4}>
                  <Field title="Aplicativo" flex="1" desc={objectData.authCode} />
                  <Field title="Descrição" flex="2" desc={objectData.authName} />
                </HStack>
                <HStack spacing={4} display="flex" justifyContent="space-between">
                  <Button colorScheme="blue" disabled={!objectData.requestSession} onClick={() => openModalJson('Dados da Sessão da Requisição', objectData.requestSession)}>Sessão da requisição</Button>
                  <Button colorScheme="blue" disabled={!objectData.requestHeaders} onClick={() => openModalJson('Dados do Cabeçalho da Requisição', objectData.requestHeaders)}>Cabeçalho Request</Button>
                  <Button colorScheme="blue" disabled={!objectData.requestData} onClick={() => openModalJson('JSON da Requisição', objectData.requestData)}>JSON Request</Button>
                  <Button colorScheme="blue" disabled={!objectData.responseHeaders} onClick={() => openModalJson('Dados do Cabeçalho da Resposta', objectData.responseHeaders)}>Cabeçalho Response</Button>
                  <Button colorScheme="blue" disabled={!objectData.responseData} onClick={() => openModalJson('JSON da Resposta', objectData.responseData)}>JSON Response</Button>
                </HStack>
              </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => closeForm(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HttpAuditRequestAccessModal;
