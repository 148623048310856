import { IMenu } from '../dtos/commons';

const menus: Array<IMenu> = [
  {
    title: 'Acessos',
    icon: 'people-arrows',
    order: 1,
    submenus: [
      {
        title: 'Usuário',
        icon: 'user',
        order: 1,
        action: '/register/user',
        permission: 'USER',
      }
    ],
  },
  {
    title: 'Aplicativos',
    icon: 'project-diagram',
    order: 3,
    submenus: [
      {
        title: 'Cadastro de Aplicativo',
        icon: 'key',
        order: 1,
        action: '/application/app-connect',
        permission: 'APPCONNECT',
      },
      {
        title: 'Manutenção de WebHook',
        icon: 'terminal',
        order: 2,
        action: '/application/webhook',
        permission: 'WEBHOOK',
      },
      {
        title: 'Monitor de Consultas',
        icon: 'sitemap',
        order: 3,
        action: '/application/request-access',
        permission: 'REQUESTACCESS',
      },
    ],
  },
  {
    title: 'Configurações',
    icon: 'tools',
    order: 4,
    submenus: [
      {
        title: 'Auditoria',
        icon: 'user-secret',
        order: 3,
        action: '/tools/audit',
        permission: 'AUDIT',
      },      
      {
        title: 'Parametros de Gerais',
        icon: 'cogs',
        order: 2,
        action: '/tools/parameters/general',
        permission: 'GENERALPARAMS',
      },
      {
        title: 'Config. Integração',
        icon: 'code-branch',
        order: 5,
        action: '/tools/integration',
        permission: 'INTEGRATION',
      },
      {
        title: 'Log Integração',
        icon: 'soap',
        order: 6,
        action: '/tools/integration-audit',
        permission: 'INTEGRATION-AUDIT',
      },
    ],
  }
];

export default menus;
