/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/toast';
import api from '../../services/api';
import DataTableComponent, { ColumnHeaders, getRowsPerPage } from '../../components/DataTable';
import { IAppConnectWebHook } from '../../dtos/app-connect-webhook';
import { Box, HStack, Input, Stack, Text } from '@chakra-ui/react';
import DateUtil from '../../utils/DataUtil';
import { IDataIntegrationSource } from '../../dtos/data-integration-source';
import Select from 'react-select';
import AppUtil from '../../utils/AppUtil';
import moment from 'moment';
import AppConnectWebHookModal from './AppConnectWebHookModal';
import { ISelectOptions } from '../../components/Form/EASelect';
import { IAppConnect } from '../../dtos/app-connect';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

interface IAppConnectWebHookDoc extends IAppConnectWebHook {
  appConnectCode?: string;
}

const AppConnectWebHook = () => {
  const Toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [actionModal, setActionModal] = useState<'VIEW' | 'EDIT' | 'DEFAULT'>('DEFAULT');
  const [dataId, setDataId] = useState<string | undefined>();
  const [dataAppConnectWebHookList, setAppConnectWebHookList] = useState<IAppConnectWebHookDoc[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [rowsPerPage] = useState(getRowsPerPage());
  const [currentPage, setCurrentPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sortable, setSortable] = useState<string|undefined>('-createdAt');

  const [appConnectList, setAppConnectList] = useState<ISelectOptions[]>([]);
  const [appConnect, setAppConnect] = useState<ISelectOptions>();
  
  const getData = async ({ filter, sort, page, offset, limit = rowsPerPage }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      setSortable(sort);
      const query: string[] = [];
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }
      
      query.push(`sort=${sort||'-createdAt'}`);
      
      setSortable(sort||'-createdAt');
      setAppConnectWebHookList([]);
      setAllRows(0);
      if (appConnect && appConnect.value && appConnect.value != 'X'){        
        const response: AxiosResponse = await api.get(`/app-connect/${appConnect?.value}/webhook/list${query.length > 0 ? `?${query.join('&')}` : ''}`);
        if (response && response.data) {
          const docs: IAppConnectWebHook[] = response.data.rows || []
          setAppConnectWebHookList(docs.map(doc => ({
            ...doc,
            error: !doc.error,
            appConnectCode: (doc?.appConnect as IAppConnect)?.code || appConnect?.label
          })));
          setAllRows(response.data.allRows);
        }
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  
  const getAppConnect = async () => {
    try {
      setLoading(true);      
      setAppConnectList([]);
      const response: AxiosResponse = await api.get(`/app-connect/?limit=15&sort=name`);
      if (response && response.data) {
        const docs: IAppConnect[] = response.data || []
        let options = docs.filter(f=> !!f.active)?.map(d=> ({value: d._id, label: d.code}))
        if (options && options.length === 0){
          options = [{
            value: 'X',
            label: 'Nenhum Aplictivo Ativo'
          }]
        }
        setAppConnect(options[0])
        setAppConnectList(options);        
      }
    } catch (e) {
      console.error('getAppConnect. Error: ', e)
    }    
    getData({});
  };
  const closeForm = (reload = false) => {
    setActionModal('DEFAULT');
    setDataId('');
    setShowModal(false);
    if (reload) {
      getData({});
    }
  };

  useEffect(() => {
      
    setHeaders([
      ///{ text: 'Recurso', value: 'resource', align: 'center', type: 'datetime', cellClass: {w: '20%', minW: '200px'}, sortable: true },
      { text: 'Aplicativo', value: 'appConnectCode', align: 'center', cellClass: {w: '20%'}, sortable: false },
      { text: 'Recurso', value: 'description', alignHeader: 'center', sortable: true },
      { text: 'Status', value: 'active', type: 'boolean', align: 'center', cellClass: {w: '10%'}, sortable: true },
      { text: 'Operando', value: 'error', type: 'boolean', align: 'center', cellClass: {w: '10%'}, sortable: true },
      { text: 'Msg Ag. Processamento', value: 'queued', align: 'center', cellClass: {w: '15%'}, sortable: true },
      { text: 'Msg Processadas', value: 'success', align: 'center', cellClass: {w: '15%'}, sortable: true },
    ]);
    getAppConnect()
  }, []);

  useEffect(() => {
    getData({});
  }, [appConnect]);
  return (
    <>
      {showModal ? <AppConnectWebHookModal dataId={dataId} action={actionModal} onCloseForm={closeForm} /> : null }
      <Stack d="flex" w="100%">
       <HStack>
          <Text>Aplicativo: </Text>
          <Box w="100%">
            <Select
              placeholder="Pesquisa"
              options={appConnectList}
              value={appConnect}
              onChange={(event) => {
                setAppConnect(event as ISelectOptions);
              }}
            />
          </Box>
          
        </HStack>
        <DataTableComponent
          isLoading={isLoading}
          headers={headers}
          itens={dataAppConnectWebHookList}
          filter={filterValue}
          paginationOptions={{
            allItems: allRows,
            itemsPerPage: rowsPerPage,
            page: currentPage,
          }}
          tableSync={(filter, sort, page, offset, limit) => {
            getData({ filter, sort, page, offset, limit });
          }}
          itemActionView={(item: IAppConnectWebHook) => {
            setDataId(item._id);
            setActionModal('VIEW');
            setShowModal(true);
          }}
          columnSortable={sortable}
        />
      </Stack>
    </>
  );
};

export default AppConnectWebHook;
