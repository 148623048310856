/* eslint-disable no-underscore-dangle */
import {  Button,  HStack, IconButton, Modal, Spinner, SkeletonText , Skeleton, Progress, ModalBody,  ModalCloseButton,  ModalContent,  ModalFooter,  Tooltip, ModalHeader,  ModalOverlay,  Stack, Tab,  TabList,  TabPanel,  TabPanels,  Tabs,  useDisclosure,  useToast } from '@chakra-ui/react';
import { faFileCode, faKey, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHandles } from '@unform/core';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import EAForm from '../../components/Form/EAForm';
import EAInput from '../../components/Form/EAInput';
import EAInputSwitch from '../../components/Form/EAInputSwitch';
import api from '../../services/api';
import { IApplicationDocumentOption, IApplicationResourceOption, IApplicationWebHook, IAppConnect } from '../../dtos/app-connect';
import { IDefaultResponseList } from '../../dtos/commons';
import EARadioInput from '../../components/Form/EARadioInput';
import { v4 } from 'uuid';
import EAConfirmationDialog from '../../components/EAConfirmationDialog';
import EASelect, { ISelectOptions } from '../../components/Form/EASelect';
import AppHeadersModal from './AppHeadersModal';
import AppCardWebHook from './AppCardWebHook';

type AppConnectFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: AppConnectFormProps) => void;
};

export interface IFeaturesWeb {
  description: string;
  feature: string;
  byCooperative: boolean;
}



const AppConnectModal = ({ dataId, action, onCloseForm }: AppConnectFormProps) => {
  const Toast = useToast();
  const [objectData, setObjectData] = useState<IAppConnect>({} as IAppConnect);
  const [webhooks, setWebhooks] = useState<IApplicationWebHook[]>([]);
  const [webhooksAux, setWebhooksAux] = useState<IApplicationWebHook[]>([]);
  const [optionsApplicationDocument, setOptionsApplicationDocument] = useState<ISelectOptions[]>([]);
  const [valueApplicationDocument, setValueApplicationDocument] = useState<ISelectOptions[]>([]);
  const [optionsApplicationResourceAux, setOptionsApplicationResourceAux] = useState<IApplicationResourceOption[]>([]);
  const [optionsApplicationResource, setOptionsApplicationResource] = useState<ISelectOptions[]>([]);
  const [valueApplicationResource, setValueApplicationResource] = useState<ISelectOptions[]>([]);
  const [openNewApiKey, setOpenNewApiKey] = useState(false)
  const [openModalHeader, setOpenModalHeader] = useState(false)
  const [webhookLoading, setWebhookLoading] = useState(false)
  const [selectResourceLoading, setSelectResourceLoading] = useState(false)
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const typeOptions = [
    {
      value: 'PRODUCER',
      label: 'Cooperado'
    },
    {
      value: 'COOPERATIVE',
      label: 'Cooperativa'
    }
  ]

  const yupSelectSchema = Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required('Value is Required'),
  })

  const formSchema = {
    id: Yup.string().default(dataId),
    code: Yup.string().trim('Não é permitido espaços em branco').required('Código é obrigatória'),
    name: Yup.string().required('Nome é obrigatório'),
    apiKey: Yup.string().when('id', { is: ((value) => !value || value === ''), then: Yup.string().required('Api Key obrigatória') }),
    type: Yup.string().required('Tipo é obrigatório'),
    documents: Yup.array().of(yupSelectSchema).min(1, 'Informe ao meno 1 registro de acesso'),
    resources: Yup.array().of(yupSelectSchema).min(1, 'Informe ao meno 1 recurso liberado'),
    active: Yup.boolean(),
  }

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };
  
  const changeType = async (type:string = 'PRODUCER'): Promise<void> => {    
    const oldType = objectData?.type
    let obj = objectData;
    obj['type'] = type
    setObjectData(obj)
    if (oldType !== type){
      // console.log('changeType: ', type, objectData.type, getFormField('type') )
      let opt: ISelectOptions[] = optionsApplicationResourceAux.filter( f=> f.type === 'ALL' || f.type === type).map( (doc) => ({ label: doc.description , value: doc.resource }))
      // console.log("GET optionsResources opt ==> ",opt)    
      setOptionsApplicationResource(opt);    
      // console.log("GET optionsResources optionsResources ==> ", optionsApplicationResource)
      
      const rvalue = valueApplicationResource.filter( f => opt.map( m => m.value).includes(f.value))
      setValueApplicationResource(rvalue)
      setFormField('resources', rvalue)

      setValueApplicationDocument([])
      setFormField('documents', [])
      
      getOptionsApplicationDocument(type)
    }
  }

  const loadOptionsApplicationDocument = async (text?: string)=>{
    // console.log('loadOptionsApplicationDocument: ', objectData.type, getFormField('type') )
    if (objectData.type === 'COOPERATIVE') {
      const { data } = await api.get<IApplicationDocumentOption[]>(`/app-connect/available/cooperative?${text ? `q=${text}&` : ''}limit=15&sort=name`);
      return data?.map( (v) => ({ label: v.name , value: v.document }))
    } else {
      const { data } = await api.get<IApplicationDocumentOption[]>(`/app-connect/available/customer-supplier?${text ? `q=${text}&` : ''}limit=15&sort=name`);
      return data?.map( (v) => ({ label: v.name , value: v.document }))
    }
  }

  
  const getOptionsApplicationDocument = async (type:string = 'PRODUCER'): Promise<ISelectOptions[]> => {
    if (type === 'COOPERATIVE') {
      const { data } = await api.get<IApplicationDocumentOption[]>('/app-connect/available/cooperative?sort=name');
      setOptionsApplicationDocument(data?.map( (doc) => ({ label: doc.name , value: doc.document })))
      return data?.map( (v) => ({ label: v.name , value: v.document }))
    } else {
      const { data } = await api.get<IApplicationDocumentOption[]>('/app-connect/available/customer-supplier?sort=name');
      setOptionsApplicationDocument(data?.map( (doc) => ({ label: doc.name , value: doc.document })))
      return data?.map( (v) => ({ label: v.name , value: v.document }))
    }
  };

  const getResources = async (): Promise<ISelectOptions[]> => {
    const { data } = await api.get<IApplicationResourceOption[]>(`/resources/list`);
    setOptionsApplicationResourceAux(data)
    // console.log("GET optionsResources ==> ",data)
    let opt: ISelectOptions[] = []
    if (data) {
      opt = data.map( (doc) => ({ label: doc.description , value: doc.resource }))
      // console.log("GET optionsResources opt ==> ",opt)
      setOptionsApplicationResource(opt);
      // console.log("GET optionsResources optionsResources ==> ", optionsApplicationResource)
    }
    return opt;
  };

  const getWebHooks = async (id: string) => {
    const response: AxiosResponse<IDefaultResponseList<IApplicationWebHook>> = await api.get<IDefaultResponseList<IApplicationWebHook>>(`/app-connect/${id}/webhook/list`);
    
    if (response && response.data) {
      setWebhooksAux(response.data.rows);
      setWebhooks(response.data.rows);
    }
  };

  const getDocument = async (id: string) => {
    const response: AxiosResponse<IAppConnect> = await api.get<IAppConnect>(`/app-connect/${id}`);
    
    setFormField('active', true)
    if (response && response.data) {
      const appDocs = await getOptionsApplicationDocument(response.data.type)
      // console.log("optionsResources ==> ", optionsApplicationResource)
      setValueApplicationDocument(appDocs?.filter( f => response.data.documents?.includes(f.value)))
      
      const appRes = await getResources()
      setValueApplicationResource(appRes.filter( f => response.data.resources?.includes(f.value) ))
      setObjectData(response.data);
      setFormField('active', !!response.data.active)
      await getWebHooks(id)
    }
  };
  
  const newApikey = () => {
    formRef.current?.setFieldValue('apiKey', v4().toString().replaceAll('-', '').toUpperCase())
  }
  
  const setFormField = (name: string, value: any) => {
    formRef.current?.setFieldValue(name, value);
  }

  const getFormField = (name: string): any => {
    return formRef?.current?.getFieldValue(name);
  }

  const getHeaderModal = () => {
    return action === 'VIEW' ? 
    'Visualizar Cadastro de Aplicativo' : 
    dataId ? 
      'Atualizar Cadastro de Aplicativo' : 
      'Novo Cadastro de Aplicativo'
  }

  const getWebhooksFromResources = (idApp: string):IApplicationWebHook[] => {
    const resource = getFormField('resources') as ISelectOptions[]
    const webhookFiltered = webhooks.filter( f => resource.map (m => m.value).includes(f.resource))
    const newResources = resource.filter(f => !webhookFiltered.map(m => m.resource).includes(f.value)).map( (val:ISelectOptions):IApplicationWebHook =>  ({
      // _id: undefined,
      appConnect: idApp,
      resource: val.value,
      description: val.label,
      //webhook: '',
      //headers: undefined,
      active: true,
    }) )
    //console.log('changePage resource => ', resource)
    //console.log('changePage webhooks => ', webhooks)
    //console.log('changePage webhookFiltered => ', webhookFiltered)
    //console.log('changePage newResources => ', newResources)
    // const result: IApplicationWebHook[] = webhookFiltered// [...webhookFiltered, ...newResources] as IApplicationWebHook[]
    const result: IApplicationWebHook[] = [...webhookFiltered, ...newResources]
    //result.push(webhookFiltered)
    // result.concat(newResources)
    result.sort((a, b) => (a.resource && b.resource ? (a.resource > b.resource ? 1 : -1) : 1))
    // console.log('changePage result => ', result)
    return result
  }
  
  const changePage = (pageIdx: number) => {
    if (pageIdx === 1){
      setWebhookLoading(true)
      try {
        const aux = getWebhooksFromResources(objectData._id);
        setWebhooks([])
        // console.log('changePage resource => ', pageIdx, aux)
        setWebhooks(aux)
      } finally{
        setWebhookLoading(false)
      }
    }
    // console.log('changePage resource 2 => ', pageIdx, webhooks)
  }
  
  const changeWebHook = (idx: number, webhook: IApplicationWebHook) => {
    let aux = webhooks;
    if (idx >= 0 ){
      aux[idx] = webhook
    }
    setWebhooks(aux)
  }

  const saveWebhook = async (idApp: string): Promise<void> => {    
    let list = getWebhooksFromResources(idApp);
    for await (const hook of list){
      const dataToSave = {
        ...hook,
        appConnect: idApp
      };      
      const saved: AxiosResponse<IApplicationWebHook> = await api.post<IApplicationWebHook>('/app-connect/webhook/save', dataToSave);
      hook._id = saved.data._id
    }
  }

  const submit = async ({id, code, apiKey, name, type, active, documents, resources}) => {
    try {
      setIsSaving(true)
      /*
      console.log('submit => ', {
        id,
        code, apiKey, name, type, active, 
        documents: documents.map( doc => doc.value ),
        resources: resources.map( doc => doc.value)
      })
      */
      // return;

      const dataToSave = {
        ...objectData,
        id,
        code: String(code).toUpperCase(), 
        apiKey,
        name: String(name).toUpperCase(),
        type,
        active, 
        documents: valueApplicationDocument.map( doc => doc.value ),
        resources: valueApplicationResource.map( doc => doc.value)
      };
      const saved: AxiosResponse<IAppConnect> = await api.post<IAppConnect>('/app-connect/save', dataToSave);
      setObjectData({...objectData, _id: saved.data._id})
      await saveWebhook(saved.data._id)
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: id ? 'Registro atualizado com sucesso!' : 'Registro inserido com sucesso!',
        position: 'top-right',
      });
      closeForm(true, saved.data);
    } catch (err) {
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err.message ,
        position: 'top-right',
      });
    } finally {      
      setTimeout(() => setIsSaving(false), 1)      
    }
  };

  useEffect(() => {
    async function getDataFromAPI() {
      onOpen();
      try {        
        setIsLoading(true)
        await getResources();
        if (dataId) {
          await getDocument(dataId);
        }
      } finally {
        setIsLoading(false)          
      }
    }

    getDataFromAPI();
  }, []);

  const handleSubmit = () => {
    formRef.current?.submitForm();
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeForm} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {getHeaderModal()}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={8} overflowX="auto">
          { isLoading ?
            <Stack>
              <Progress 
                isIndeterminate
                height='30px' 
                borderRadius='5'
                alignContent="center" display="flex" justifyContent="center"
              >
                Loading...
              </Progress>

              <Skeleton height='22px' width='15%'/>
              <Skeleton height='45px'/>

              <Skeleton height='22px' width='15%'/>
              <Skeleton height='45px'/>
              
              <Skeleton height='22px' width='15%'/>
              <Skeleton height='45px'/>
              
              <Skeleton height='22px' width='15%'/>
              <Skeleton height='45px'/>
              
              <Skeleton height='22px' width='15%'/>
              <Skeleton height='45px'/>
              
              <Skeleton height='22px' width='20%'/>
              <Skeleton height='45px'/>
            </Stack> :
            <>
              <EAForm formRef={formRef} onSubmit={submit} yupSchema={formSchema}>
                <Tabs isFitted variant="enclosed" onChange={changePage}>
                  <TabList mb="1em">
                    <Tab>Aplicativo</Tab>
                    <Tab>WebHook</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Stack spacing="2">
                        <EAInput
                          name="id"
                          isInvisible
                          defaultValue={ dataId || '' }
                        />                    
                        <EAInput
                          name="code"
                          label="Código"
                          isRequired
                          isUpperCase
                          isReadOnly={action === 'VIEW'}
                          defaultValue={ objectData.code || ''}
                          autoComplete="none"
                        />
                        <EAInput
                          name="name"
                          label="Nome"
                          isRequired
                          isUpperCase
                          isReadOnly={action === 'VIEW'}
                          defaultValue={ objectData.name || ''}
                          autoComplete="none"
                        />
                        <HStack alignContent="baseline" align="end">
                          <EAInput
                            name="apiKey"
                            label="Api Key"
                            isRequired
                            isReadOnly
                            defaultValue={objectData?.apiKey || ''}
                            autoComplete="none"
                          />
                          <Tooltip label='Gerar Nova Api Key'>
                            <IconButton
                              ml={0}
                              colorScheme='green'
                              aria-label='New Api Key'
                              disabled={action === 'VIEW'}
                              icon={<FontAwesomeIcon icon={faKey} />}
                              onClick={() => !objectData?.apiKey ? newApikey() : setOpenNewApiKey(true)}
                            />                      
                          </Tooltip>
                        </HStack>
                        <EARadioInput
                          name="type"
                          label="Tipo de Acesso"
                          isRequired
                          defaultValue={objectData.type || 'PRODUCER'}                      
                          radioOptions={typeOptions}
                          isReadOnly={action === 'VIEW'}
                          onChange={(ev)=> {                
                            changeType(ev)
                          }}
                        />            
                        <EASelect
                          formRef={formRef}
                          name="documents"                      
                          label={`Acessos Concedidos`}
                          placeholder={`Filtre pelo nome ou documento`}
                          value={valueApplicationDocument}                      
                          isClearable
                          isReadOnly={action === 'VIEW'}
                          onChange={(value)=> setValueApplicationDocument(value) }
                          // asyncSearch={loadOptionsApplicationDocument}
                          options={optionsApplicationDocument}
                          styles={{
                            container: (styles) => ({ ...styles, flex: 1 }),
                          }}
                          isMulti
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <EASelect
                          formRef={formRef}
                          name="resources"                      
                          label={`Recursos`}
                          placeholder={`Filtre pelo recurso`}
                          value={valueApplicationResource}                      
                          isClearable
                          isReadOnly={action === 'VIEW'}
                          onChange={(value)=> setValueApplicationResource(value) }
                          options={optionsApplicationResource}
                          styles={{
                            container: (styles) => ({ ...styles, flex: 1 }),
                          }}
                          isMulti
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <EAInputSwitch
                          name="active"
                          label="Ativo"
                          isReadOnly={action === 'VIEW'}
                          defaultChecked={objectData?.active === undefined ? true : objectData.active}                      
                        />
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      { 
                        webhookLoading ?
                        <HStack>
                          <Progress size='xs' isIndeterminate /> 
                        </HStack> :       
                        <Stack>            
                          <Button
                            colorScheme="facebook"
                            mr={3}
                            icon={<FontAwesomeIcon icon={faFileCode} />}
                            onClick={() => setOpenModalHeader(true)}>
                            Cabeçalho Padrão das requisições
                          </Button>
                          {webhooks.map( (val, idx) => <AppCardWebHook key={`webhook-${idx}`} idx={idx} webhook={val} action={action} /*onChange={(i, value) => console.log(` ${i} - `, value)}*/ />)}
                        </Stack>
                      }
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </EAForm>
              { 
                openModalHeader ? 
                <AppHeadersModal 
                  formTitle="Cabeçalho Padrão"
                  value={objectData.headers}
                  action={action}
                  onCloseForm={(cancel, data)=>{
                    if(!cancel)
                      setObjectData({...objectData, headers: data})
                    setOpenModalHeader(false)
                  }} /> : 
                null
              }
              <EAConfirmationDialog
                isOpenDialog={openNewApiKey}
                textHeader="Atenção"
                textBoby={`Confirma Geração de uma Nova Api Key?`}
                textActionOne="Sim"
                actionOneProps={{
                  colorScheme: "red",
                  onClick: () => {
                    setOpenNewApiKey(false)
                    newApikey()
                  }
                }}
                textActionTwo="Não"
                actionTwoProps={{
                  onClick: () => {
                    setOpenNewApiKey(false)
                  }
                }}
              />
            </>
          }
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={action === 'VIEW' || isSaving || isLoading}
            isLoading={isSaving}
            loadingText="Saving..."
            colorScheme="green"
            mr={3}
            leftIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={handleSubmit}>
            Salvar
          </Button>
          <Button 
            isDisabled={ isSaving || isLoading }
            mr={3} 
            colorScheme="blackAlpha" 
            leftIcon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => closeForm(false)}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AppConnectModal;
