/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/toast';
import api from '../../services/api';
import DataTableComponent, { ColumnHeaders, getRowsPerPage } from '../../components/DataTable';
import { IAudit } from '../../dtos/audit';
import AuditModal from './HttpAuditRequestAccessModal';
import { HStack, Input, Stack } from '@chakra-ui/react';
import DateUtil from '../../utils/DataUtil';
import moment from 'moment';
import { IAppConnectRequestAccess } from '../../dtos/app-connect-reques-access';
import HttpAuditRequestAccessModal from './HttpAuditRequestAccessModal';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

const HttpAuditRequestAccess = () => {
  const [showModal, setShowModal] = useState(false);
  const [actionModal, setActionModal] = useState<'VIEW' | 'EDIT' | 'DEFAULT'>('DEFAULT');
  const [dataId, setDataId] = useState('');
  const [AuditList, setAuditList] = useState<IAppConnectRequestAccess[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [rowsPerPage] = useState(getRowsPerPage());
  const [currentPage, setCurrentPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sortable, setSortable] = useState<string|undefined>('-time');
  const [initDate, setInitialDate] = useState<string>(DateUtil.stringFormatt(DateUtil.addDays(new Date(), -15), 'YYYY-MM-DD'));
  const [finishDate, setFinishDate] = useState<string>(DateUtil.stringFormatt(new Date(), 'YYYY-MM-DD'));

  const getData = async ({ filter, sort, page, offset, limit = rowsPerPage }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      setSortable(sort);
      const query: string[] = [];
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (initDate){
        query.push(`initialdate=${ moment(initDate).toJSON() }`);
      }
      if (finishDate){
        query.push(`finishdate=${ moment(finishDate).toJSON() }`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }
      
      query.push(`sort=${sort||'-time'}`);

      setAuditList([]);
      setAllRows(0);
      const response: AxiosResponse = await api.get(`app-connect/request-access/list${query.length > 0 ? `?${query.join('&')}` : ''}`);
      if (response && response.data) {
        const docs: IAppConnectRequestAccess[] = response.data.rows || []
        setAuditList(docs.map((a: IAppConnectRequestAccess)=> ({
          ...a,
          query: {}
        })));
        setAllRows(response.data.allRows);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const closeForm = (reload = false) => {
    setActionModal('DEFAULT');
    setDataId('');
    setShowModal(false);
    if (reload) {
      getData({});
    }
  };

  useEffect(() => {
    setHeaders([
      { text: 'Data', value: 'time', type: 'datetime', align: 'center', cellClass: {w: '15%'}, sortable: true},
      { text: 'Aplicativo', value: 'authCode', alignHeader: 'center', cellClass: {w: '20%'}, sortable: true },
      { text: 'Método', value: 'method', align: 'center', cellClass: {w: '6%'}, sortable: true },
      { text: 'HTTP Status', value: 'responseStatusCode', align: 'center', cellClass: {w: '6%'}, sortable: false },
      { text: 'Recurso/URL', value: 'uri', alignHeader: 'center', sortable: true },
      // { text: 'Dt. Processamento', value: 'processedAt', type: 'datetime', align: 'center', cellClass: {w: '25%'}, sortable: false }
    ]);
    getData({});
  }, []);
  
  useEffect(() => {
    getData({});
  }, [initDate, finishDate]);
  return (
    <> 
      {
      showModal ? <HttpAuditRequestAccessModal dataId={dataId} action={actionModal} onCloseForm={closeForm} /> : null
      }
      <Stack d="flex" w="100%">
      <HStack>
        <Input
            placeholder="Data Inicial"
            type='date'
            maxW="200px"
            minW="180px"
            value={initDate}
            onChange={(event) => {
              setInitialDate(event.target.value);
            }}
          />
          <Input
            placeholder="Data Final"
            type='date'
            maxW="200px"
            minW="180px"
            value={finishDate}
            onChange={(event) => {              
              setFinishDate(event.target.value);              
            }}
          />
        </HStack>
        <DataTableComponent
          isLoading={isLoading}
          headers={headers}
          itens={AuditList}
          filter={filterValue}
          paginationOptions={{
            allItems: allRows,
            itemsPerPage: rowsPerPage,
            page: currentPage,
          }}
          tableSync={(filter, sort, page, offset, limit) => {
            getData({ filter, sort, page, offset, limit });
          }}
          itemActionView={(item: IAudit) => {
            setDataId(item._id);
            setActionModal('VIEW');
            setShowModal(true);
          }}
          columnSortable={sortable}
        />
      </Stack>
    </>
  );
};

export default HttpAuditRequestAccess;
