import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Switch, SwitchProps } from '@chakra-ui/switch';

interface EAInputSwitchProps extends SwitchProps {
  name: string;
  label?: string;
}

const EAInputSwitch: React.FC<EAInputSwitchProps> = ({ name, label, isReadOnly, isDisabled, isRequired, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked'
    });
  }, [fieldName, registerField]);


  return (    
    <FormControl 
      isInvalid={!!error}  
      display="flex"
      justifyContent="space-between" 
      alignItems="center" 
      isReadOnly={isReadOnly}
      isRequired={isRequired}
    >
      <FormLabel htmlFor={"inputSwitch-"+fieldName} size="xs">{label}</FormLabel>        
      <Switch
        id={"inputSwitch-"+fieldName } 
        isDisabled={isReadOnly||isDisabled}
        ref={inputRef}
        colorScheme="green" size="lg"
        {...props} 
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
};

export default EAInputSwitch;
