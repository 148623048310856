import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Input, InputGroup, InputLeftAddon, InputLeftElement, InputProps, InputRightAddon, InputRightElement } from '@chakra-ui/input';
import { Radio, RadioGroup, Stack, useRadio, useRadioGroup } from '@chakra-ui/react';
import { FormHandles, useField,  } from '@unform/core';
import { darken } from 'polished';
import React, { useCallback, useEffect, useRef } from 'react';

interface EARadioInputOptions {
  value: string;
  label: string;
}

interface EARadioInputProps extends InputProps {
  name: string;
  radioOptions: Array<EARadioInputOptions>;
  radioDirection?: 'row' | 'column';
  label?: string;
  isInvisible?: boolean;
  isReadOnly?: boolean
  isRequired?: boolean
  onChange?: (valeu: any) => void;
}

const EARadioInput: React.FC<EARadioInputProps> = ({ name, radioOptions, radioDirection = 'row',label, isReadOnly, isRequired, isInvisible, defaultValue,onChange = ()=>{}, ...props }) => {
  const [radioValue, setRadioValue] = React.useState<string>(defaultValue as string)
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, error, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

    
  useEffect(() => {
    if (inputRef && inputRef.current){
      inputRef.current.value = radioValue
    }
    onChange(radioValue)
  }, [radioValue]);
  
  if (isInvisible || !radioOptions || radioOptions.length <= 0){
    return null
  } else {
    return (
      <FormControl isInvalid={!!error} isRequired={isRequired} isReadOnly={isReadOnly} >        
        <FormLabel htmlFor={`radio-group-${fieldName}`} size="xs">{label}</FormLabel>
        <RadioGroup
          id={`radio-group-${fieldName}`}
          size="md"
          defaultValue={defaultValue as string}
          // value={radioValue}
          onChange={setRadioValue}
        >
          <input id={`input-radio-${fieldName}`} ref={inputRef} style={{ display: 'none' }} />
          <Stack direction={radioDirection}>
            {
              radioOptions.map(option => (
              <Radio 
                key={`radio-${fieldName}-${option.value}`}
                value={option.value} 
                isDisabled={isReadOnly}
              >
              {option.label}
              </Radio>))
            }
          </Stack>
        </RadioGroup> 
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    )
  }
};

export default EARadioInput;
